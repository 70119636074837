<template>
  <div class="">
    <draggable
        :disabled="!canEdit(project.cans)"
        :list="currentData"
        :group="{ name: dropGroup, pull: 'clone' }"
        v-bind="dragOptions"
        :clone="cloneItem"
        @start="startTheDrag($event)"
        @end="dropTheItem($event)"
        class="flex flex-wrap gap-8 gap-x-10 card-box mt-8 overflow-y-auto bld-bottom-card-h">
      <div
          v-for="(item, chunkIndex) in filteredResult"
          :level="item.level ? item.level : 1"
          :id="item.id"
          :module_id="item.module_id"
          class="flex-shrink-0 bld-bottom-card cursor-pointer bg-primary-three rounded i-border-1 border-secondary-five i-pl-15 i-pr-15 i-pt-15 i-pb-15 relative"
          :key="chunkIndex"
          :class="{'bg-primary-four': parseInt(item.id) === parseInt(selectedItem.id)}"
          @click="clickedEvent(item.id, item.level)">
        <div class="flex items-baseline text-left">
          <h3 class="font-poppins text-float text-primary-one fs-13 fw-600 w-full ellipse" :title="item.title" :class="{'text-primary-three': parseInt(item.id) === parseInt(selectedItem.id)}">
            <span v-if="level === 0" class="mr-1 w-full ellipse">Project {{ item.project ? item.project.name : '' }}:</span>{{ item.title }}
          </h3>
        </div>
        <p class="text-left mt-1 font-poppins fs-13 secondary-one i-h-80 overflow-scroll break-words"
           :class="{'text-primary-three': parseInt(item.id) === parseInt(selectedItem.id)}"
           v-if="topSelectedButton !== 'full_screen'" v-html="item.description" />
        <div class="absolute bld-bottom-level-indicator">
          <div class="flex">
            <div class="circle bld-l-circle bg-secondary-one opacity-10" :class="{'opacity-100': level >= 1}"></div>
            <div class="circle bld-l-circle bg-secondary-one opacity-10 ml-1" :class="{'opacity-100': level >= 2}"></div>
            <div class="circle bld-l-circle bg-secondary-one opacity-10 ml-1" :class="{'opacity-100': level >= 3}"></div>
            <div class="circle bld-l-circle bg-secondary-one opacity-10 ml-1" :class="{'opacity-100': level >= 4}"></div>
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit } = usePermission()
</script>

<script>
  import {mapGetters} from 'vuex';

  export default {
    name: "CurrentData",
    props: ['topSelectedButton', 'searchQuery', 'selectedItem', 'viewer', 'level'],
    data() {
      return {
        groupList: ['identifier', 'area', 'area_measure', 'measure'],
        dropGroup: '',
        bottomAreaSelection: "",
      }
    },
    methods: {
      toggleBottomAreaSelection(index) {
        this.bottomAreaSelection = index;
      },
      clickedEvent(id, level) {
        if (this.viewer) {
          level = level > 0 ? level : 0;
          this.$store.dispatch("build/loadViewerModeData", {
            id: id,
            level: level
          });
        }
      },
      cloneItem() {
        return;
      },
      startTheDrag(e) {
        let level = e.item.getAttribute('level');
        this.dropGroup = this.groupList[level - 1];
      },
      dropTheItem(e) {
        const self = this;
        self.$Progress.start();
        let level = e.item.getAttribute('level');
        let copiedId = e.item.getAttribute('id');
        let moduleId = e.item.getAttribute('module_id');
        if (moduleId) {
          let parentId = null;
          if (e.to.getAttribute("area-measure-parent-data-id")) {
            parentId = e.to.getAttribute("area-measure-parent-data-id");
          } else if (e.to.getAttribute("area-parent-data-id")) {
            parentId = e.to.getAttribute("area-parent-data-id");
          } else if (e.to.getAttribute("data-id")) {
            parentId = e.to.getAttribute("data-id");
          }

          let data = {
            copied_id: copiedId,
            index: e.newIndex,
            module_id: self.navigatorModule,
            level: level,
            parent_id: parentId,
            global: true,
            module_replace: false
          };

          let canvasId = null;
          let parentNode = e.to.parentNode;
          if(parentNode) {
            canvasId = parentNode.getAttribute("module-id");
          }

          if (parentId || e.to.getAttribute("module-id") || canvasId) {
            self.$store.dispatch("build/storeDataFromLibrary", data).then(response => {
              self.$Progress.finish();
            });
          } else {
            self.$Progress.finish();
          }
        } else {
          let data = {
            copied_id: copiedId,
            module_id: self.navigatorModule,
            module_replace: true,
            project_id: self.$route.params.id
          };

          let targetClassCount = e.to.className.split(' '); // please remove when found a good solution
          if (e.to.getAttribute("module-id") || e.to.getAttribute("dropped-area") || targetClassCount.includes('enable-drag')) {
            if (confirm('This will delete all the dependencies of the current screen (eg. child, property, association etc)?')) {
              self.$store.dispatch('build/replaceModule', data).then(response => {
                self.$Progress.finish();
              });
            } else {
              self.$Progress.finish();
            }
          } else {
            self.$Progress.finish();
          }
        }

        self.dropGroup = '';
      }
    },
    computed: {
      ...mapGetters({
        currentData: "build/currentData",
        navigatorModule: "build/navigatorModule",
        project: "project/project",
      }),
      filteredResult() {
        if (this.searchQuery) {
          return this.currentData.filter((item) => {
            return this.searchQuery
                .toLowerCase()
                .split(" ")
                .every((v) => item.title.toLowerCase().includes(v));
          });
        } else {
          return this.currentData;
        }
      },
      dragOptions() {
        return {
          animation: 200,
          disabled: this.viewer,
          ghostClass: "ghost",
        };
      },
    },
    watch: {
      selectedItem: function (val) {
        let itemIndex = this.filteredResult.findIndex(item => parseInt(item.id) === parseInt(val.id));
        if (itemIndex >= 0) {
          let targetItem = this.filteredResult[itemIndex];
          this.filteredResult.splice(itemIndex, 1);
          this.filteredResult.unshift(targetItem);
        }
      }
    }
  }
</script>

<style scoped>
/*   @media all and (max-width: 1850px) and (min-width: 1300px){
    .card-box{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
      gap: 32px;
    }
    .card-box>div{
      max-width: 600px;
      width: 100%;
    }
  } */
</style>
