<template>
  <div class="w-full" @click="closeMenuHandler($event)">
    <div class="relative flex _main_wrapper" :class="!visibleleft&&'no-left-panel-visible'">

      <div class="i-build-left-panel z-10 shadow-one flex-shrink-0"  v-if="visibleleft" >
        <div class="relative">
          <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
          <NavToggle v-if="projectId" />
        </div>

        <left-part
          v-if="modules.length > 0"
          ref="build_left_panel"
          :viewer="viewer"
          @manageListClickCount="manageListClickCount"
        />
      </div>


      <div class="i-right-panel full-height"
          :class="{
                    'bld-full-w-no-left':
                        topSelectedButton === 'full_screen' && !visibleleft || (!visibleleft),
                        'bottom-space': topSelectedButton === 'full_screen'
                }">
        <!-- <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo absolute full-screen-logo" v-if="!visibleleft"> -->
        <div class="ws-heading-box flex flex-col relative">
          <workspace-menu/>
          <div class="absolute" style="top: 10px">

            <div class="flex gap-16 mb-6">
              <div class="flex gap-1 items-center">
                <h2 class="fs-14 fw-800 text-primary-one">Active Scenario: </h2>
                <h2 class="custom_select fw-500 fs-14 bg-transparent pr-2">
                  {{ project && project.scenario ? project.scenario.title : 'N/A' }}
                </h2>
              </div>
              <div class="flex gap-1 items-center">
                <h2 class="fs-14 fw-800 text-primary-one">Data Source: </h2>
                <select class="custom_select fw-500 fs-14 bg-transparent pr-2">
                  <option value="Scenario Properties">Scenario Properties</option>
                  <!-- <option value="null">Assessment (Coming Soon)</option>-->
                </select>
              </div>
              <div class="flex gap-1 items-center">
                <h2 class="fs-14 fw-800 text-primary-one">Linked Goal: </h2>
                <select @change="bindGoal()" v-model="selectedGoalId" class="custom_select fw-500 fs-14 bg-transparent pr-2">
                  <option selected>Select Goal</option>
                  <option v-for="goal in goals" :key="goal.id" :value="goal.id">
                    {{ goal.title }}
                  </option>
                  <option :value="null">None</option>
                </select>
              </div>
            </div>

            <div class="flex gap-x-5 items-center relative">
              <!-- <img src="/images/icons/build/navigation.svg" alt="icon" class="bld-icon cursor-pointer opacity-50 i-icon mr-5 hover:bg-primary-three"
                   @click="manageListClickCount(), expandedArray = []" v-if="topSelectedButton === 'full_screen' && !visibleleft" title="Navigation"> -->
              <!-- <img src="/images/icons/master_view.svg" alt="icon" class="bld-icon opacity-50 cursor-pointer i-icon hover:bg-primary-three"
                   @click="masterView()" title="Master View"> -->
              <!-- <img src="/images/icons/build/editor.svg" alt="icon" class="bld-icon ml-5 opacity-50 cursor-pointer i-icon hover:bg-primary-three"
                   @click="toggleToEditor()" title="Editor Mode" v-if="visibleleft" :class="{'opacity-100': !viewer}"> -->
              <!-- <img src="/images/icons/build/viewer.svg" alt="icon" class="bld-icon ml-5 opacity-50 cursor-pointer i-icon hover:bg-primary-three"
                   @click="toggleToViewer()" title="Viewer Mode" v-if="visibleleft" :class="{'opacity-100': viewer}"> -->
                <FullscreenBorderedIcon
                  class="w-10 h-10 minimum-shadow"
                  v-if="topSelectedButton === 'full_screen' && !visibleleft"
                  @click.native="manageListClickCount(), expandedArray = []"
                  title="Navigation"
                />
                <MasterViewBorderedIcon
                  class="w-10 h-10 minimum-shadow"
                  @click.native="masterView()"
                  title="Master view"
                />
                <ProjectContentBorderedIcon
                  class="w-10 h-10 minimum-shadow"
                  @click.native="toggleToEditor()"
                  title="Project Viewer"
                  :active="!viewer"
                />
                <ContentViewBorderedIcon
                  class="w-10 h-10 minimum-shadow"
                  @click.native="toggleToViewer()"
                  title="Content Viewer"
                  :active="viewer"
                />
                <DuplicateBorderedIcon
                  class="w-10 h-10 minimum-shadow"
                  title="Copy Scenario (Coming Soon)"
                />
                <EditBorderedIcon
                  class="w-10 h-10 minimum-shadow"
                  title="Sketch (Coming Soon)"
                />
                <router-link :to="{name: 'buildVisualization', params: {projectId: $route.params.id}}">
                  <MonitorBorderedIcon
                    class="w-10 h-10 minimum-shadow"
                    title="Quick Visualize"
                  />
                </router-link>
              <input type="text"
                class="font-poppins fs-14 text-secondary-one fw-700 uppercase ml-5 bg-transparent w-244"
                v-model="moduleObj.title"
                :disabled="!canEdit(project.cans)"
                @change="updateModuleTitle()" />
            </div>
          </div>
        </div>
        <div style="margin-top: 15px">
          <div
              class="build-scrollable-screen"
              :class="{
                'bld-full-screen':
                topSelectedButton === 'full_screen' && !visibleleft,
                'border-0': topSelectedButton === 'full_screen' && visibleleft,
                'build-scrollable-screen-full': !visibleleft && topSelectedButton === 'full_screen'
              }"
          >
            <div class="pl-10 pr-5">
              <div
                class="flex justify-between block-context-area pr-8 i-mt-6 enable-drag"
                :class="{'i-border-b-1 border-solid border-secondary-five bld-contents': topSelectedButton !== 'full_screen', 'bld-contents-full': topSelectedButton === 'full_screen'}"
                :module-id="activeModule"
                :dropped-area="true"
              >
                <draggable
                  :disabled="!canEdit(project.cans)"
                  v-model="buildData"
                  @start="changeTransition('flip-list')"
                  @end="changeTransition('null'), updateStore($event)"
                  v-bind="dragOptions"
                  group="identifier"
                  class="flex enable-drag"
                  :dropped-area="true"
                >
                  <!-- <transition-group
                      :name="idTransitionName"
                      mode="out-in"
                      class="flex bld-transition-tiles ws-scrollbar i-n-mt-9"
                      :class="{
                        'bld-full-regular-no-left':
                        topSelectedButton === 'full_screen' && !visibleleft && expandedArray.length <= 0,
                        'bld-full-regular-with-left':
                        topSelectedButton === 'full_screen' && visibleleft,
                        'build-null-div': buildData.length === 0,
                        'bld-full-max-no-left':
                        topSelectedButton === 'full_screen' && !visibleleft && expandedArray.length > 0,
                      }"
                      id="x-scrollable"
                      :module-id="activeModule"
                      :dropped-area="true"
                  > -->
                    <identifier-input-group
                      v-for="(l1Collection, index) in buildData"
                      :key="index"
                      :identification="identification"
                      :identifier="l1Collection"
                      :index="index"
                      :module_id="activeModule"
                      :appearance="appearance"
                      :indexTitle="indexTitle"
                      @click="balanceUpDown(l1Collection.id)"
                      @add="addIdentifier(l1Collection.parent)"
                      @remove="removeIdentifier(l1Collection.id)"
                      @toggleToBottomItemSelection="toggleToBottomItemSelection"
                      @toggleExpansion="toggleExpansion"
                      @showCntxtmenu="showCntxtmenu"
                      @resetView="checkMasterView"
                      @activePropertyOption="activePropertyOption"
                      ref="inputGroup"
                      class=""
                      :class="{
                        'i-ml-39': index !== 0 && visibleleft,
                        'i-ml-38': index !== 0 && !visibleleft,
                      }"
                      :identifier-id="l1Collection.id"
                      :viewer="viewer"
                    >
                    </identifier-input-group>
                  <!-- </transition-group> -->
                </draggable>
              </div>
            </div>
          </div>
          <div
              v-if="topSelectedButton !== 'full_screen'"
              class="pl-10 pr-8"
              :class="{
                'identification-full-screen':
                    topSelectedButton === 'full_screen',
                'identification-full-screen-bottom-height':
                    topSelectedButton === 'full_screen',
              }"
          >
            <bottom-card
                :topSelectedButton="topSelectedButton"
                :selectedItem="selectedItem"
                :selectedItemLevel="selectedItemLevel"
                :viewer="viewer"
            />
          </div>
        </div>
      </div>
    </div>
    <context-menu
        :menu="getMenu"
        :item="contextmenuItem"
        :id="'context-menu-'+activeModule"
        v-show="contextmenu"
        @appearance="applyAppearnce"
        @indexTitle="applyIndexTitle"
        @background="applyBackground"
        @addNew="addNew"
        @deleteLevelItem="deleteLevelItem" />
    <!-- <context-menu :item="contextmenuItem" :id="'context-menu-'+module_id" v-show="contextmenu" @appearance="applyAppearnce" @indexTitle="applyIndexTitle" @background="applyBackground" @description="applyDescription"/> -->

    <Nav v-model="showNavigation" @close="showNavigation=false"/>
  </div>
</template>

<script setup>
import store from '@/store'
import usePermission from '@/composable/usePermission.js'
import NavToggle from '@/components/navigation/components/NavToggle.vue'
import useNavigation from '@/components/navigation/useNavigation.js'
import Nav from '@/components/navigation/Nav.vue'
import axios from 'axios'
import { ref, computed, onMounted } from 'vue'
import router from "@/routes/routes.js"


const route = router.currentRoute;
const { canCreate, canEdit } = usePermission()
const { projectId, showNavigation } = useNavigation()
const goals = ref([])
const workspaceId = ref(null)
const selectedGoalId = ref(null)
const getSelectedModuleId = computed(() => store.getters['build/navigatorModule'])
const getBuildData = computed(() => store.getters["build/data"])
const getWorkspaceId = async () => {
  const {data} = await axios.get(`projects/${route.params.id}?solid_object=true`).then(({data}) => data)
  workspaceId.value = data?.workspace_id
}
const getGoals = async (workspaceId) => {
  const { data } = await axios.get(`workspaces/goals/${workspaceId}/all-goals`).then(({data}) => data)
  goals.value = data
}

function transformData(data) {
  return data.map(({id, child}) => ({
    id,
    items: child.length > 0 ? transformData(child) : []
  }));
}


const bindGoal = async () => {
  const payload = {
    goal_id: selectedGoalId.value,
    module_id: getSelectedModuleId.value,
    items: transformData(getBuildData.value)
  }

  await axios.post(`workspaces/goals/link-with-build`, payload)
  store.dispatch('build/getModuleDataOnLeftNavigator', getSelectedModuleId.value)
  store.dispatch('build/getModules', route.params.id)
}
const _modules = computed(() => store.getters["build/projectModules"])
onMounted(async () => {
  await getWorkspaceId()
  getGoals(workspaceId.value)
  selectedGoalId.value = _modules.value.find(module => module.id == getSelectedModuleId.value)?.goal_id
})
</script>

<script>
import $ from "jquery";
import {mapActions, mapGetters} from "vuex";
import BottomCard from "@/components/build_identification/BottomCard";
import LeftPart from "@/components/build_identification/LeftPart";
import WorkspaceMenu from "@/components/InstMenu/WorkspaceMenu";
import ContextMenu from "@/components/build_identification/CntxtMenu";

export default {
  name: 'BuildIdentification',
  created() {
    const self = this;
    document.title = "Build Project";
    self.$Progress.start();
    self.$store.dispatch("project/getSolidObject", self.$route.params.id).then(response => {
      if(response && response.active_module_id) {
        this.$store.dispatch("build/toggleLeftNavigatorModuleSelection", response.active_module_id)
        this.$store.dispatch("build/getModulePropertySet", response.active_module_id)
      } else {
        if(!this.navigatorModule) {
          this.selectedModule = this.modules[0].id;

        } else {
          this.selectedModule = this.navigatorModule;
        }
        this.$store.dispatch("build/toggleLeftNavigatorModuleSelection", this.selectedModule).then(
            this.$store.dispatch("build/getModulePropertySet", this.selectedModule)
        );
      }
    });

    self.$store.dispatch("workspace/projectSelection", self.$route.params.id);
    self.$store.dispatch("build/loadWorkspaceModules", self.$route.params.id);

    this.$store.dispatch("build/getModules", this.$route.params.id).then(response => {
      if (response && response.status) {
        self.$store.dispatch("build/fetchIndexData", {module: self.activeModule, initial: true}).then(response => {
          this.checkMasterView()
          self.$Progress.finish();
        });
        self.$store.dispatch("build/loadCurrentData");
      } else {
        self.$Progress.finish();
      }
    });

  },
  data() {
    return {
      listClickedCount: 1,
      viewer: false,
      visibleleft: true,
      searchQuery: null,
      identification: 1,
      loadingTitle: "Identifier",
      idCounts: 1,
      balanceId: null,
      minId: 1,
      maxId: 3,
      master: 1,
      openView: false,
      contextmenu: false,
      appearance: 0,
      indexTitle: true,
      description: false,
      idTransitionName: null,
      levels: [
        {
          id: 1,
          title: "IDENTIFIER",
          parent: 0,
          array: [],
        },
        {
          id: 2,
          title: "AREA",
          parent: 1,
          array: [],
        },
        {
          id: 3,
          title: "AREA MEASURE",
          parent: 2,
          array: [],
        },
        {
          id: 4,
          title: "MEASURE",
          parent: 3,
          array: [],
        },
      ],
      itemGroup: {parents: [], childs: []},
      identifierOptionButton: false,
      selectedItem: {},
      selectedItemLevel: 1,
      expandedArray: [],
      idListGroup: [
        {
          id: 1,
          title: "I1.001 IDENTIFIER 1",
          shortTitle: "ID1.001",
          list: [
            {
              id: 1,
              item: (
                  <identifier-input-group ref="inputGroup"></identifier-input-group>
              ),
            },
          ],
        },
      ],
      moduleObj: {}
    };
  },
  computed: {
    ...mapGetters({
      topSelectedButton: "GET_IDENTIFIER_TOP_OPTION",
      selectedLevel: "GET_SELECTED_LEVEL",
      buildData: "build/data",
      activeModule: "build/navigatorModule",
      modules: "build/projectModules",
      contextmenuItem: "build/contxtmenuCollection",
      moduleSettings: "build/currentSettings",
      colorCodes: "global/colorCodes",
      project: "project/project",
    }),
    getMenu() {
      return {
        description: false,
        isolation: true,
        remove_isolation: false
      };
    },
    dragOptions() {
      return {
        animation: 200,
        // group: "description",
        disabled: this.viewer,
        ghostClass: "ghost",
        class: 'cursor-default'
      };
    },
    getModuleTitle() {
      let moduleObj = this.modules.find(item => parseInt(item.id) === parseInt(this.activeModule));
      if(moduleObj) {
        this.setModuleObj(moduleObj);
        return moduleObj.title;
      }
      return '';
    },
    resultQuery() {
      if (this.searchQuery) {
        return this.loadingList.filter((item) => {
          // return console.log(this.searchQuery);
          return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
        });
      } else {
        return this.loadingList;
      }
    },
    loadingList() {
      if (this.loadingTitle == "Area") {
        let areaList = [];
        for (let [key, value] of Object.entries(
            this.$refs.inputGroup
        )) {
          for (let [index, area] of Object.entries(value.areaList)) {
            areaList.push(area);
          }
        }
        return areaList;
      } else {
        return this.idListGroup;
      }
    },
    levelItemGroupQuery() {
      let items = [];
      items.parents = this.levels.find(
          (el) => el.id == this.selectedLevel
      ).array;
      items.childs = this.levels.find(
          (el) => el.parent == this.selectedLevel
      ).array;

      return items;
    },
    hideContextMenu() {
      return document
          .getElementsByClassName("open-menu")
          .forEach((el) =>
              el.addEventListener("contextmenu", (e) =>
                  e.preventDefault()
              )
          );
    },
  },
  methods: {
    deleteLevelItem() {
      if(confirm("are you sure want to delete this?")) {
        this.$Progress.start();
        this.$store.dispatch("build/deleteItem", this.contextmenuItem).then(response => {
          this.$Progress.finish();
          this.contextmenu = false;
        });
      }
    },
    setModuleObj(module) {
      this.moduleObj = module;
    },
    updateModuleTitle() {
      if(this.moduleObj && this.moduleObj.id) {
        this.$store.dispatch("project/updateModule", {
          id: this.moduleObj.id,
          title: this.moduleObj.title
        });
      }
    },
    toggleLeftVisibility() {
      if(this.topSelectedButton === "full_screen") {
        this.visibleleft = !this.visibleleft;
      }
    },
    manageListClickCount() {
      if(this.listClickedCount === 3) {
        this.listClickedCount = 1;
      } else {
        this.listClickedCount++;
      }

      if(this.listClickedCount === 1) {
        this.topButtonSelection(null);
        this.visibleleft = true;
      } else if(this.listClickedCount === 2) {
        this.topButtonSelection('full_screen');
        this.visibleleft = true;
      } else if(this.listClickedCount === 3) {
        this.topButtonSelection('full_screen');
        this.visibleleft = false;
      }
    },
    nextIdentifier() {
      this.identifierOptionButton = !this.identifierOptionButton;
    },
    toggleToEditor() {
      this.viewer = false;
      this.$store.dispatch("build/moveToEditorMode");
    },
    toggleToViewer() {
      this.viewer = true;
      this.$store.dispatch("build/moveToViewerMode");
    },
    toggleDropdown(index) {
      this.topDropdown[index].clicked = !this.topDropdown[index].clicked;
    },
    toggleDropdownOption(parent, child) {
      const childItem = this.topDropdown[parent].subOptions[child];
      this.topDropdown[parent].title = childItem.title;
      this.topDropdown[parent].index = child;
      this.topDropdown[parent].clicked = false;
    },
    topButtonSelection(value) {
      this.$store.dispatch("ACT_IDENTIFIER_TOP_OPTION", value);
      this.$store.dispatch("ACT_TOGGLE_BRAINSTORM_TOP_BUTTON", value);
      if (value === 'full_screen') {
        if (this.viewer) {
          this.viewer = false;
          this.$store.dispatch("build/moveToEditorMode");
        }
      }
    },
    toggleBottomDropdown(serial) {
      const self = this;
      self.bottomOptions.forEach((option) => {
        if (option.serial === serial) {
          option.clicked = !option.clicked;
        } else {
          option.clicked = false;
        }
      });
    },
    filterWithBottomSection(root, child) {
      this.bottomOptions[root].selectedIndex = child;
      this.bottomOptions[root].selected = this.bottomOptions[
          root
          ].subOptions[child].title;
      this.bottomOptions[root].clicked = false;

      this.getLoadingTitle(this.bottomOptions[root].selected);
    },
    getLoadingTitle(selected) {
      this.loadingTitle = selected;
    },
    changeTransition(value) {
      return (this.idTransitionName = value);
    },
    balanceIdentifiers(count) {
      this.balanceId = count - this.maxId - 1; // find the id from where it should balance
      for (let index = 0; index <= this.balanceId; index++) {
        this.$refs.inputGroup[index].changeTransition("fade");
        this.$refs.inputGroup[index].showGroup == true
            ? this.$refs.inputGroup[index].groupToggle()
            : console.log("do nothing");
      }
    },
    balanceUpDown(index) {
      // console.log(index);
    },
    // expandAll() {
    //   this.openView = true;
    //   this.master = false;
    //   for (let index = 0; index < this.buildData.length; index++) {
    //     this.$refs.inputGroup[index].showGroup = true;
    //   }

    //   $("#x-scrollable").animate(
    //       {
    //         scrollLeft: 0,
    //       },
    //       800
    //   );
    // },
    masterView() {
      if (this.master < 3) {
        this.master++
      }
      else {
        this.master = 1
      }

      this.checkMasterView()

      $("#x-scrollable").animate(
          {
            scrollLeft: 0,
          },
          800
      );
    },
    checkMasterView() {
      if (this.master == 1) {
        if(this.$refs.inputGroup) {
          for (let index = 0; index < 3; index++) {
            if (this.$refs.inputGroup[index]) {
              this.$refs.inputGroup[index].showGroup = true;
            }
          }
          for (let index = 3; index < this.buildData.length; index++) {
            if (this.$refs.inputGroup[index]) {
              this.$refs.inputGroup[index].showGroup = false;
            }
          }
        }
      }
      else if (this.master == 2){
        for (let index = 0; index < this.buildData.length; index++) {
          if (this.$refs.inputGroup[index]) {
            this.$refs.inputGroup[index].showGroup = true;
          }
        }
      }
      else if (this.master == 3){
        for (let index = 0; index < this.buildData.length; index++) {
          if (this.$refs.inputGroup[index]) {
            this.$refs.inputGroup[index].showGroup = false;
          }
        }
      }
    },
    getIdentifierSliderValue(e) {
      let value = e.target.value;
      // console.log(value)
      this.idCounts = 1;
      this.idListGroup = [
        {
          id: 1,
          title: "I1.001 IDENTIFIER 1",
          list: [
            {
              id: 1,
              item: (
                  <identifier-input-group ref="inputGroup"></identifier-input-group>
              ),
            },
          ],
        },
      ];
      // for (let i = 1; i < value; i++) {
      //   this.addParent();
      // }
    },
    changelevel(level) {
      this.$store.dispatch("ACT_SELECTED_LEVEL", level);
      this.itemGroup = [];
      this.itemGroup = this.levelItemGroupQuery;
    },
    unselectAll() {
      for (let id = 0; id < this.idCounts; id++) {
        this.$refs.inputGroup[id].selectedActive = [];
        this.$refs.inputGroup[id].levelSelf = 1;
        this.$refs.inputGroup[id].levelGlobal = 1;
        this.$refs.inputGroup[id].expansionSelf = false;
        this.$refs.inputGroup[id].expansionGlobal = false;
      }
    },
    updateStore(e) {
      this.$Progress.start();
      let itemId = e.item.getAttribute("identifier-id");
      let data = {
        id: parseInt(itemId),
        index: parseInt(e.newIndex),
        old_index: parseInt(e.oldIndex),
        module_id: this.activeModule
      };
      this.$store.dispatch("build/reOrderItem", data).then(response => {
        this.$Progress.finish();
      });
    },
    toggleToBottomItemSelection(item) {
      let itemArray = [
        { level: 1, title: 'identifier' },
        { level: 2, title: 'area' },
        { level: 3, title: 'area_measure' },
        { level: 4, title: 'measure' },
      ];
      let contentItem = itemArray.find(content => content.title === item[1]);
      if(item && item[0] && contentItem) {
        this.selectedItem = item[0];
        this.selectedItemLevel = contentItem.level;
      }
    },
    showHideLeftPart(value) {
      this.visibleleft = !value
    },
    toggleExpansion(item) {
      if(this.expandedArray.length > 0) {
        let index = this.expandedArray.findIndex(ex => parseInt(ex.index) === parseInt(item.index));
        if(index >= 0) {
          if(this.expandedArray[index].expanded) {
            this.expandedArray.splice(index, 1);
          } else {
            this.expandedArray[index] = item;
          }
        }
      } else {
        this.expandedArray.push(item);
      }
    },
    getExpandedOrNot() {
      let expandedArray = this.expandedArray.filter(item => item.expanded);
      return expandedArray.length > 0;
    },
    activePropertyOption() {
      this.$refs.build_left_panel.toggleSelectedOption('property');
    },
    getBg(level) {
      let settings = this.moduleSettings.collections[level];
      if(settings) {
        let indexLevel = level - 1;
          let color = this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
          return color[settings.color];
      }
      return "";
    },
    showCntxtmenu() {
      var context = document.getElementById('context-menu-'+this.activeModule);
      setTimeout(() => {
        let obj = this.contextmenuItem
        if (context && obj) {
          context.style.left = obj.left+'px'
          context.style.top = obj.top+'px'
          this.contextmenu = true
        }
      }, 100);

    },
    closeMenuHandler(e) {
      var context = document.getElementById('context-menu-'+this.activeModule);
      if (context && !context.contains(e.target) && !e.target.closest('.action_items')) {
        this.contextmenu = false
        this.$store.commit("build/setContxtmenuCollection", null);
      }
    },
    applyIndexTitle(value) {
      this.indexTitle = value
      this.contextmenu = false
    },
    applyAppearnce(value) {
      this.appearance = value
      this.contextmenu = false
    },
    applyBackground(value) {
      this.contextmenuItem.background_color = value;
      this.regularUpdate(this.contextmenuItem);
      this.contextmenu = false
    },
    addNew(value) {
      let parent = this.contextmenuItem
      let chcount = parent.child.length
      let order = 1;
      if (chcount > 0) {
        order = chcount + 1
      }
      let obj = {};
      obj.parent_id = parent.id;
      obj.title = this.moduleSettings.collections[parent.level + 1].title;
      obj.level = parent.level + 1;
      obj.item_order = order;
      obj.order = order;
      obj.module_id = parent.module_id;
      this.$Progress.start();
      if (value == 1) {
        this.$store.dispatch("build/storeGroupData", obj).then(response => {
          this.$Progress.finish();
        });
      }
      else if (value == 2) {
        this.$store.dispatch("build/storeData", obj).then(response => {
          this.$Progress.finish();
        });
      }

      this.contextmenu = false

    },
    regularUpdate(obj) {
      this.$store.dispatch("build/regularUpdate", obj);
    },
  },
  mounted() {
    this.checkMasterView()
  },
  components: {
    WorkspaceMenu,
    BottomCard,
    LeftPart,
    ContextMenu
  },
  watch: {
    activeModule: function (val) {
      this.getModuleTitle;

      this.master = 1
      for (let index = 0; index < this.buildData.length; index++) {
        if (this.$refs.inputGroup[index]) {
          this.$refs.inputGroup[index].showGroup = false;
        }
      }
      this.$store.dispatch(
          "build/fetchIndexData",
          {module: val},
      ).then(
        this.checkMasterView
      );
    },
    modules: function(val) {
      if(val && val.length > 0 && !this.moduleObj && !this.moduleObj.id) {
        this.moduleObj = this.modules[0];
      }
    }
  }
};
</script>

<style scoped>
input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  width: 1.5ex;
  height: 0.7ex;
  background-color: rgba(0, 0, 0, 0);
  top: 1ex;
  left: 0.8ex;
  border: 2px solid #223945;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input[type="checkbox"] {
  line-height: 2.1ex;
}

input[type="checkbox"] {
  position: absolute;
  left: -999em;
}

textarea::placeholder {
  color: rgba(255, 255, 255, 0.4) !important;
  font-family: Avenir Next LT Pro;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 45px;
  letter-spacing: 0.05em;
}

input[type="checkbox"] + label {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

input[type="checkbox"]:checked + label::before {
  content: "";
  display: inline-block;
  vertical-align: -25%;
  height: 3ex;
  width: 3ex;
  background-color: white;
  border: 1px solid rgb(166, 166, 166);
  border-radius: 2px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
  margin-right: 0.5em;
}

input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  vertical-align: -25%;
  height: 3ex;
  width: 3ex;
  background-color: transparent;
  border: 1px solid rgb(166, 166, 166);
  border-radius: 2px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
  margin-right: 0.5em;
}

/* animation for  flip area  */
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.3;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  background: #38383a;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 27px;
  height: 15px;
  border-radius: 2px;
  background: #f9f9f9;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 27px;
  height: 15px;
  background: #f9f9f9;
  border-radius: 2px;
  cursor: pointer;
}

/* animation for  slide area  */
.slide-leave-active,
.slide-enter-active {
  transition: 0.7s;
}

.slide-enter-active {
  transition-delay: 0.3s;
}

.slide-enter {
  opacity: 0;
  transform: translate(100%, 0%);
}

.slide-leave-to {
  opacity: 0;
  transform: translate(100%, 0%);
}

#x-scrollable::-webkit-scrollbar {
  display: none;
}

.build-slider {
  height: 2px;
  background: #6E6E6E;
}

.build-slider::-webkit-slider-thumb {
  background: #315467;
}

.build-slider-number {
  color: #5F5F6A;
}

.input-group {
  margin-top: 15px;
  margin-bottom: 15px;
}

.input-group:last-child {
  margin-right: 0px !important;
}

.input-group:first-child {
  margin-left: 15px !important;
}
.build-scrollable-screen {
  width: 1520px;
  overflow-x: auto;
  margin-top: 23px;
}
.build-scrollable-screen-full {
  width: 1890px;
}
</style>
