<template>
  <div
    v-click-outside="onClickOutside"
    class="rounded profile-creator-div identifier-selection-up"
    :class="{'pointer-events-none': viewer}"
  >
    <div class="creation-slider fs-14 mt-10 mb-10 text-secondary-one">
      <p class="track-title">Number of Groups:</p>
      <div class="track-box mb-2">
        <input :disabled="!canEdit(project.cans)" class="range cursor-pointer bg-secondary-two w-full" min="0" max="5" type="range" v-model="lev1" @input="createItems(1)"/>
      </div>
      <div class="flex justify-between">
        <span
          v-for="(i, index) in 6"
          :key="index"
          :style="{ opacity: lev1 == index ? 1 : .5 }"
          >{{ index }}</span
        >
      </div>
    </div>

    <div class="creation-slider fs-14 mt-10 mb-10 text-secondary-one">
      <p class="track-title">Number of Areas:</p>
      <div class="track-box mb-2">
        <input :disabled="!canEdit(project.cans)" class="range cursor-pointer bg-secondary-two w-full" min="0" max="5" type="range" v-model="lev2" @input="createItems(2)"/>
      </div>
      <div class="flex justify-between">
        <span
          v-for="(i, index) in 6"
          :key="index"
          :style="{ opacity: lev1 == index ? 1 : .5 }"
          >{{ index }}</span
        >
      </div>
    </div>

    <h3 class="flex items-start justify-between border-solid i-border-t-1 border-secondary-one pt-10 fs-14 fw-600 text-primary-one i-mb-30">Create New</h3>
    <!-- level 1 creator  -->
    <div class="cursor-pointer create-button shadow-two  flex relative items-center rounded i-pl-15 text-primary-three fw-600 mb-8"
        :style="{ background: getBg(1) }"
        :class="item.level == 1 ? 'border-solid i-border-1 border-primary-three' : ''"
        @click="createMode = 'identifier'; item.level = 1; getTheList();">
        <!-- CREATE {{ activeModuleLevel[0].title }} -->
        <span>Level 1 {{ tagsGroup[0] }}</span>
    </div>
    <!-- level 2 creator  -->
    <div class="cursor-pointer create-button shadow-two flex relative items-center rounded i-pl-15 text-primary-three fw-600 mb-8"
        :style="{ background: getBg(2) }"
        :class="item.level == 2 ? 'border-solid i-border-1 border-primary-three' : ''"
        @click="createMode = 'area'; item.level = 2; getTheList();">
        <!-- CREATE {{ activeModuleLevel[1].title }} -->
        <span>Level 2 {{ tagsGroup[1] }}</span>
    </div>
    <!-- level 3 creator  -->
    <div class="cursor-pointer create-button shadow-two flex relative items-center rounded i-pl-15 text-primary-three fw-600 mb-8"
        :style="{ background: getBg(3) }"
        :class="item.level == 3 ? 'border-solid i-border-1 border-primary-three' : ''"
        @click="createMode = 'area_measure'; item.level = 3; getTheList();">
        <!-- CREATE {{ activeModuleLevel[2].title }} -->
        <span>Level 3 {{ tagsGroup[2] }}</span>
    </div>
    <!-- level 4 creator  -->
    <div class="cursor-pointer create-button shadow-two flex relative items-center rounded i-pl-15 text-primary-three fw-600 mb-8"
        :style="{ background: getBg(4) }"
        :class="item.level == 4 ? 'border-solid i-border-1 border-primary-three' : ''"
        @click="createMode = 'measure'; item.level = 4; getTheList();">
        <!-- CREATE {{ activeModuleLevel[3].title }} -->
        <span>Level 4 {{ tagsGroup[3] }}</span>
    </div>

    <draggable
      :disabled="!canEdit(project.cans)"
      :list="currentList"
      :group="{ name: createMode, pull: 'clone' }"
      v-bind="dragOptions"
      :clone="cloneItem"
      @end="dropTheItem($event)"
    >
      <div class="cursor-pointer create-div relative shadow-two rounded overflow-hidden">
        <div class="input-div text-primary-one absolute z-10 w-full bg-primary-three">
          <h3 class="mt-2 ml-4 fs-14 fw-700">
            <input
              type="text"
              v-model="item.title"
              placeholder="Title"
              id="input-title"
              :disabled="!createMode"
              class="bg-transparent input-field"
              autocomplete="off"
              @change="titleUpdate"
            />
          </h3>
          <textarea
            cols="46"
            rows="8"
            class="bg-transparent build-manual-box fs-12 ml-4 input-field bld-content-area"
            placeholder="Descriptive text"
            v-model="item.description"
            :disabled="!createMode"
          ></textarea>
        </div>
        <!-- {{item}} -->
        <img class="absolute top-0 left-0 w-full h-full z-0 _my_img_size" id="img-show" :src="previewURL" onerror="this.style.display='none'"/>
        <div v-if="canEdit(project.cans)" class="absolute flex justify-between items-center mini-button z-20">
          <span>
            <img class="img-input-button cursor-pointer" @click="()=>{canEdit(project.cans) && clickOnInput()}" src="/images/icons/build/menu_alt.svg" alt="image"/>
          </span>
          <span
            class="bg-transparent fs-12 cursor-pointer tag-creator text-primary-three rounded-sm text-center"
            :style="{ background: item.level != 0 ? getBg(item.level) : '#2B80AD' }">
            {{ item.tag ? item.tag : 'Add tag' }}
          </span>
        </div>
        <input
          id="img-input"
          class="absolute hidden"
          accept="image/*"
          type="file"
          @change="(e)=>{onChange(e); uploadImage(file);}"
        />
      </div>
    </draggable>
  </div>
</template>

<script setup>
  import { useFile } from '@/composable/useFile.js'
  const { onChange, previewURL, file } = useFile()

  import usePermission from '@/composable/usePermission.js'
  const { canEdit } = usePermission()
</script>

<script>
import { mapActions, mapGetters } from "vuex";
import buildCommon from "../../common/build";
import axios from 'axios'

export default {
  name: "ManualCreate",
  props: ['viewer'],
  data() {
    return {
      tagsGroup: ['Group', 'Item', 'Item', 'Item'],
      buildCommon: null,
      item: {
        title: "",
        description: "",
        level: 0,
      },
      createMode: null,
      currentList: [],
      lev1: 0,
      lev2: 0,
      dragOptions: {
        animation: 200,
        disabled: true,
        ghostClass: "ghost",
      },
      image: null
    };
  },
  methods: {
    getTheList() {
      this.item = {
        id: "",
        title: "",
        description: "",
        tag: this.tagsGroup[this.item.level - 1],
        level: this.item.level,
      };
      if (this.item.level === 1) {
        this.currentList = this.identifiersList;
      } else if (this.item.level === 2) {
        this.currentList = this.areasList;
      } else if (this.item.level === 3) {
        this.currentList = this.areaMeasuresList;
      } else if (this.item.level === 4) {
        this.currentList = this.measuresList;
      }
    },
    cloneItem() {
      if (this.item.title) {
        if (this.item.level === 1) {
          this.item.id = this.lastIdentifier.id;
          return this.item;
        }
      }
    },
    uploadImage(file){
      this.image = file
    },
    dropTheItem(e) {
      this.$Progress.start();
      let data = this.item;
      let parentId = null;
      if (e.to.getAttribute("module-id")) {
        data.module_id_from_drag = e.to.getAttribute("module-id");
      } else if (e.to.getAttribute("area-measure-parent-data-id")) {
        parentId = e.to.getAttribute("area-measure-parent-data-id");
      } else if (e.to.getAttribute("area-parent-data-id")) {
        parentId = e.to.getAttribute("area-parent-data-id");
      } else if (e.to.getAttribute("data-id")) {
        parentId = e.to.getAttribute("data-id");
      }

      data.parent_id = parentId;
      data.module_id = this.activeModule;
      data.index = parseInt(e.newIndex);
      data.from_right_side = true;
      
      this.$store.dispatch("build/storeData", data).then(response => 
      {
        this.$Progress.finish();
        let formData = new FormData()
        formData.append('id', response.data.data.id)
        formData.append('image', this.image)
        axios.post('projects/scenarios/collections/image', formData)
        document.getElementById("img-show").src = ''
      });

      this.item = {
        title: "",
        description: "",
        level: 0,
        tag: ""
      };
      this.dragOptions.disabled = true

      this.createMode = null;
    },
    onClickOutside(event) {
      this.item = {
        title: "",
        description: "",
        level: 0,
      };
      this.dragOptions.disabled = true
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getBg(level) {
      let collectionSettings = this.moduleSettings;
      if(collectionSettings && collectionSettings.collections.length > 0) {
        let colorCodes = this.getLevelWiseColorCode(level);
        return colorCodes[collectionSettings.collections[level].color];
      }
      return null;
    },
    createItems(lev) {
      let data = {};
      data.level = lev;
      data.module_id = this.activeModule;
      data.counter1 = parseInt(this.lev1);
      data.counter2 = parseInt(this.lev2);
      this.$store.dispatch("build/multiStoreData", data).then(
        this.$store.dispatch("build/fetchIndexData", {
          module: this.activeModule,
        })
      );
    },
    clickOnInput() {
      let input = document.getElementById("img-input");
      input.click();
    },
    /* changeImg(e) {
      let display = document.getElementById("img-show");
      display.style.display = 'block'
      display.src = window.URL.createObjectURL(e.target.files[0]);
      display.onload = function() {
        window.URL.revokeObjectURL(display.src);
      };
    }, */
    titleUpdate() {
      if (this.item.title != '' && this.item.level != 0) {
        console.log(this.dragOptions);
        this.dragOptions.disabled = false
      }
    }
  },
  computed: {
    ...mapGetters({
      identifiersList: "Identifiers/data",
      areasList: "Areas/data",
      areaMeasuresList: "AreaMeasures/data",
      measuresList: "Measures/data",
      lastIdentifier: "Identifiers/entry",
      activeModule: "build/navigatorModule",
      activeModuleBg: "GET_CURRENT_MODULE_BG",
      activeModuleLevel: "GET_SELECTED_MODULE_SELECTION",
      moduleSettings: "build/currentSettings",
      colorCodes: "global/colorCodes",
      project: "project/project",
    }),

  },
  created() {
    this.buildCommon = buildCommon;
  },
};
</script>

<style scoped>
.manual-create-box::placeholder {
  color: #c2c2c2;
}
.track-box {
  width: 275px;
}
.range {
  height: 1px;
  outline: none;
  -webkit-appearance: none;
}
.range[type="range"]::-webkit-slider-thumb {
  width: 27px;
  -webkit-appearance: none;
  height: 15px !important;
  cursor: pointer;
  background: #2B80AD;
  border-radius: 2px;
}
.create-button {
  width: 275px;
  height: 50px;
}
.create-div {
  height: 218px;
}
.mini-button {
  width: 245px;
  top: 15px;
  left: 15px;
}
.tag-creator {
  padding: 5px 10px;
  width: 75px;
  height: 28px;
}
.tag-creator::placeholder {
  color: #F9F9F9;
}
.input-div {
  bottom: 0;
  opacity: .9;
  height: 50%;
}
.input-div .input-field::-webkit-input-placeholder {
  color: #1c1c1d;
}

.input-div .input-field:-moz-placeholder {
  /* Firefox 18- */
  color: #1c1c1d;
}

.input-div .input-field::-moz-placeholder {
  /* Firefox 19+ */
  color: #1c1c1d;
}

.input-div .input-field:-ms-input-placeholder {
  color: #1c1c1d;
}

.input-div .input-field::placeholder {
  color: #1c1c1d;
}
.build-manual-box {
  height: 55px;
  width: 90%;
}
#img-show {
  object-fit: cover;
}
</style>
