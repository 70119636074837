<template>
  <div>
    <div class="relative i-border-1 border-secondary-two rounded w-full i-mt-30 i-h-50">
      <input
          type="text"
          class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two i-h-50"
          placeholder="Search"
      />
    </div>

    <div class="mt-8 bld-navigation overflow-y-auto">
      <div class="flex-flex-col pl-10 pr-10" v-for="(module, index) in modules" :key="index" :class="{'mt-8': index > 0}">
        <div class="relative">
          <div class="shadow-one i-h-50 w-full rounded flex items-center cursor-pointer bg-primary-three hover:bg-secondary-five"
               :class="{'shadow-two hover:bg-rgb-primary-four': selectedModule === module.id}"
               :style="{'background': selectedModule === module.id ? getBg(module) : ''}"
               @click="toggleModuleSelection(module.id, index)" style="z-index: 1;">
            <h2 class="font-poppins fw-600 text-primary-one fs-14 px-4 capitalize ellipse w-full"
                :class="{'text-primary-three': selectedModule === module.id}">{{ module.title }}</h2>
          </div>
        </div>
      </div>
      <div v-if="canCreate(project.cans)" class="pl-10 mt-8 pr-10">
        <div @click="addModule()" class="fs-14 fw-600 text-primary-one bg-primary-three rounded i-h-50 shadow-two pl-3 flex w-full items-center text-left mt-8 cursor-pointer hover:bg-secondary-five">
          + Add Module
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit } = usePermission()
</script>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "ModuleList",
  props: ['viewer'],
  created() {
    if (this.modules.length > 0) {
      const self = this;
      self.levelColorCodes = this.getLevelWiseColorCode(1);
      if(!self.navigatorModule) {
        self.selectedModule = self.modules[0].id;

      } else {
        self.selectedModule = self.navigatorModule;
      }
      if(!this.navigatorModule) {
        // self.$store.dispatch("build/toggleLeftNavigatorModuleSelection", self.selectedModule).then(
        //     self.$store.dispatch("build/getModulePropertySet", self.selectedModule)
        // );
      }
    }
  },
  data() {
    return {
      selectedModule: '',
      // activeModuleCollection: '',
      itemSelected: '',
      moduleExpand: true,
      levelColorCodes: []
    }
  },
  computed: {
    ...mapGetters({
      modules: "build/projectModules",
      navigatorModule: "build/navigatorModule",
      buildData: "build/data",
      moduleSettings: "build/currentSettings",
      activeModuleCollection: "build/activeModuleCollection",
      colorCodes: "global/colorCodes",
      project: "project/project"
    })
  },
  watch: {
    navigatorModule: function (val) {
      this.selectedModule = val;
    }
  },
  methods: {
    addModule() {
      this.$Progress.start();
      this.$store.dispatch("programmatic/addModule", {
        project_id: this.$route.params.id
      }).then(response => {
        this.$Progress.finish();
        if(response.data.data) {
          let data = response.data.data;
          this.$store.state.build.projectModules.push(data);
        }
      });
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    toggleModuleSelection(id, index) {
      const self = this;
      self.$Progress.start();
      self.selectedModule = id;
      self.$store.dispatch("build/toggleLeftNavigatorModuleSelection", id);
      self.$store.dispatch("build/getModulePropertySet", id);
      self.$store.dispatch("workspace/updateProjectData", {
        id: this.$route.params.id,
        active_module_id: id,
        workspace_id: this.project.workspace_id,
        name: this.project.name
      });
      self.$store.dispatch("build/getModuleDataOnLeftNavigator", id).then(response => {
        self.$Progress.finish();
      });
      self.$store.dispatch("ACT_SET_MODULE_LEVEL", index + 1);
    },
    toggleSelection(itemObject) {
      this.itemSelected = itemObject;
      this.$store.dispatch("build/getActiveModuleCollection", itemObject.id);
      this.$store.dispatch("build/getBrainstorms", itemObject.id);
      this.$store.dispatch("build/getActiveBrainstorm", null);
      // this.activeModuleCollection = itemObject;
    },
    toggleExpand(itemObject) {
      itemObject.expanded = !itemObject.expanded;
    },
    toggleModuleExpand() {
      this.moduleExpand = !this.moduleExpand;
    },
    getBgStyle(index) {
      if (this.moduleSettings && this.moduleSettings.collections) {
        return this.moduleSettings.collections[index].color;
      }
      return '';
    },
    getIndexTitle(root, parentCodeIndex, index, title) {
      let nextNumber = index + 1;
      let returnTitle = '';
      if (this.moduleSettings && this.moduleSettings.collections) {
        if (this.moduleSettings.index_id) {
          returnTitle = this.moduleSettings.collections[parentCodeIndex].index_id;
        }
        if (this.moduleSettings.index_number) {
          if (root) {
            let number = parseInt(this.moduleSettings.collections[parentCodeIndex].index_number) + parseInt(index);
            returnTitle = returnTitle + number;
          } else {
            returnTitle = returnTitle + this.moduleSettings.collections[parentCodeIndex - 1].index_number + '.' + nextNumber;
          }
        }
        return returnTitle + ' ' + title;
      } else {
        return title;
      }
    },
    getBg(module) {
      let collectionSettings = JSON.parse(module.collection_settings);
      if(collectionSettings.collections[0].custom_bg) {
        return collectionSettings.collections[0].custom_bg;
      }
      let colorCodes = this.getLevelWiseColorCode(1);
      return colorCodes[collectionSettings.collections[0].color];
    }
  }
}
</script>

<style scoped>

</style>
