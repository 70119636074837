<template>
  <div>
    <div class="flex items-center justify-between pr-8 mt-5">
      <div class="relative i-h-30">
        <input
            type="text"
            class="bg-transparent bld-bottom-search focus:outline-none px-4 py-2 i-h-30 rounded fs-14 font-poppins text-secondary-one placeholder-secondary-two i-border-1 border-secondary-two border-solid"
            placeholder="Search"
            v-model="searchQuery"
        />
        <div class="mr-2 flex items-center justify-between absolute inset-y-0 right-0 pointer-events-none pr-2">
          <img
              class="w-3 h-3 opacity-50"
              src="/images/icons/search_icon.svg"
          />
        </div>
      </div>
      <div class="flex items-center justify-between bld-selector-div">
        <div class="relative mr-8">
          <select class="fs-12 font-poppins text-secondary-one ws-selector i-h-30 bg-transparent rounded i-border-1 border-solid border-secondary-two px-4 bld-bottom-selector" v-model="library">
            <option v-for="(opt, index) in librariesOption" :key="index" :value="opt.serial">{{ opt.title }}</option>
          </select>
        </div>
        <div class="relative">
          <select class="fs-12 font-poppins text-secondary-one ws-selector i-h-30 bg-transparent rounded i-border-1 border-solid border-secondary-two px-4 bld-bottom-selector" v-model="level">
            <option v-for="(opt, index) in activeModuleLevel" :key="index" :value="opt.level">{{ opt.title }}</option>
          </select>
        </div>
      </div>
    </div>
    <current-data :topSelectedButton="topSelectedButton" :searchQuery="searchQuery" :selectedItem="selectedItem" :viewer="viewer" :level="level" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CurrentData from "./CurrentData";
export default {
  name: "BottomCard",
  components: {CurrentData},
  props: ['topSelectedButton', 'selectedItem', 'viewer', 'selectedItemLevel'],
  data() {
    return {
      searchQuery: '',
      librariesOption: [
        {
          serial: 1,
          title: "Project",
        },
        {
          serial: 0,
          title: "Workspace",
        },
      ],
      level: 1,
      library: 1
    }
  },
  computed: {
    ...mapGetters({
      activeModule: "GET_CURRENT_MODULE_LEVEL",
      activeModuleLevel: "GET_SELECTED_MODULE_SELECTION",
    })
  },
  watch: {
    selectedItemLevel: function(val) {
      this.level = val;
    },
    library: function(val) {
      this.$store.state.build.library = val;
      if(val === 0) {
        this.$store.dispatch("build/loadBottomGlobal", this.$route.params.id);
      } else if(val === 1) {
        this.$store.dispatch("build/loadCurrentData");
      }
    },
    level: function(val) {
      this.$store.state.build.level = val;
      if(this.library === 0) {
        this.$store.dispatch("build/loadBottomGlobal", this.$route.params.id);
      } else if(this.library === 1) {
        this.$store.dispatch("build/loadCurrentData");
      }
    }
  },
}
</script>

<style scoped>
</style>
