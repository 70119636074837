<template>
    <div class="property-body fs-14 text-secondary-one">
        <div class="mb-6 relative">
          <img :src="(activeModuleCollection.file) ? activeModuleCollection?.file?.file_path : '/images/build/demo_image_1.jpg'" alt="image" class="block w-full _my_img_size">
          <label class="absolute right-1 bottom-1 property-image-icon cursor-pointer">
            <input @input="(e)=>{onChange(e), updateImage(
                {file, id:activeModuleCollection.id},
                ()=>activeModuleCollection.file = {file_path: previewURL}
              )}"
              :disabled="!canEdit(project.cans)"
              hidden type="file" accept="image/x-png,image/jpeg" />

            <button v-if="canEdit(project.cans)" class="pointer-events-none">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
                <path d="M0 12.3477H12V10.3477H0V12.3477ZM0 7.34766H18V5.34766H0V7.34766ZM0 2.34766H12V0.347656H0V2.34766Z" fill="#E2E2E2"/>
              </svg>
            </button>
          </label>
        </div>

        <div class="mb-10" v-if="activeModuleCollection && Object.keys(activeModuleCollection).length !== 0">
          <div class="flex items-center">
<!--            <span class="text-primary-one ls-5 font-poppins fs-14 fw-600">{{ activeModuleCollection.index_code }}</span>-->
            <input type="text" class="focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer truncate text-primary-one fw-600 ls-5 font-poppins fs-14 truncate"
                   :disabled="!canEdit(project.cans)"
                   v-model="activeModuleCollection.title"
                   @change="updateItem(activeModuleCollection)" />
          </div>
          <!-- <p v-if="activeModuleCollection && Object.keys(activeModuleCollection).length !== 0"
             :contenteditable="canEdit(project.cans)"
             @input="updateItem(activeModuleCollection, $event)"
             class="fs-12 w-full bg-transparent prop-item-desc break-words none-resize">
            {{ activeModuleCollection.description }}
          </p> -->

          <TextareaAutoHeight
            v-if="activeModuleCollection && Object.keys(activeModuleCollection).length !== 0"
            v-model="activeModuleCollection.description"
            :isDisabled="!canEdit(project.cans)"
            @onType="updateItem(activeModuleCollection)"
          />

        </div>
        <router-link :to="{path: '/build/'+$route.params.id+'/properties/'+activeModule+'/'+activeModuleCollection.id}" :event="activeModule && activeModuleCollection.id ? 'click' : ''" :title="!activeModuleCollection.id ? 'Please, select an item first.' : ''">
          <div class="flex justify-between cursor-pointer items-center pb-3 mb-8 i-border-b-1 border-secondary-two">
            <p class="fw-500 fs-12 text-secondary-two">
              Manage properties and Association
            </p>
            <img src="/images/icons/build/manage_property.svg" alt="icon" />
          </div>
        </router-link>
        <div class="p-0 bg-transparent relative">
            <div class="i-border-b-1 border-secondary-two i-mb-30" v-for="(dataset, index) in properties.filter(el => el.is_visible)" :key="index">
                <div class="flex justify-between items-center cursor-pointer i-pb-25 fw-600">
<!--                  <span class="">{{ dataset.title }}</span>-->
                  <input type="text" v-model="dataset.title" class="bg-transparent text-primary-one fw-600 w-full" @input="updateProperty(dataset)" />
                  <img :id="'down-arrow-'+index" class="p-2" src="/images/icons/build/chevron_down.svg" alt="image" @click="statusShow(index)">
                  <img :id="'up-arrow-'+index" class="p-2 hidden" src="/images/icons/build/chevron_up.svg" alt="image" @click="statusHide(index)">
                </div>
                <div class="hidden" :id="'value-cont-'+index">
                  <div class="flex justify-start items-center i-mb-25" v-for="(prop, pIndex) in dataset.property_collections.filter(el => el.is_visible)" :key="pIndex" @mouseover="showValue(index, pIndex)" @mouseleave="hideValue(index, pIndex)">
<!--                      <p class="prop-items i-mr-15">{{ prop.title }}</p>-->
                      <input type="text" v-model="prop.title" class="bg-transparent prop-items i-mr-15" @input="updateParameter(prop)" />
                      <property-sample 
                        v-if="!prop.option || prop.option == 1"
                        :property="prop"
                        :index="index"
                        :p-index="pIndex"
                        :item="activeModuleCollection"
                        :viewer="viewer"
                        @updateParameterData="updateData"
                      />
                      <input-property 
                        v-if="prop.option == 2"
                        :property="prop"
                        :index="index"
                        :p-index="pIndex"
                        :item="activeModuleCollection"
                        :viewer="viewer"
                        @updateParameterData="updateData" 
                      />
                  </div>
                </div>
            </div>
        </div>
        <div class="prop-set-div i-border-b-1 border-secondary-two">
            <div class="flex justify-between items-center cursor-pointer i-pb-25 fw-600">
              <span>Associated content</span>
            </div>
            <div class="flex justify-between items-center i-mb-25" v-for="(item, index) in associatedData" :key="index">
                <p class="assoc-items i-pr-15">
                  {{ item.associated ? item.associated.title : '' }}
                </p>
                <img :disabled="!canDelete(project.cans)" src="/images/icons/build/off_outline_close.svg" alt="image" class="p-2 cursor-pointer" @click="removeFromAssociation(item.id)">
            </div>
        </div>
    </div>
</template>

<script setup>
  import axios from 'axios'
  import TextareaAutoHeight from '@/elements/inputs/TextareaAutoHeight'
  import { useFile } from '@/composable/useFile.js'
  import usePermission from '@/composable/usePermission.js'
  const { onChange, previewURL, file } = useFile()
  const { canCreate, canEdit, canDelete } = usePermission()
</script>

<script>
    import { mapGetters } from 'vuex';
    import PropertySample from "../properties/PropertySample";
    import InputProperty from "@/components/properties/InputProperty";
    export default {
      name: "Property",
      components: {InputProperty, PropertySample},
      props: ['viewer'],
        data() {
          return {
            // active : this.properties[0]
          }
        },
        computed: {
          ...mapGetters({
              properties: "build/modulePropertySet",
              activeModuleCollection: "build/activeModuleCollection",
              activeModule: "build/navigatorModule",
              associatedData: "association/associatedData",
              project: "project/project",
          }),
        },
        watch: {
          activeModuleCollection: function (newProp, oldProp) {
            this.getAssociatedData();
          }
        },
        methods: {
          statusShow(index) {
            let cont = document.getElementById('value-cont-'+index)
            let up = document.getElementById('up-arrow-'+index)
            let down = document.getElementById('down-arrow-'+index)
            cont.style.display = 'block'
            up.style.display = 'block'
            down.style.display = 'none'
          },
          statusHide(index) {
            let cont = document.getElementById('value-cont-'+index)
            let up = document.getElementById('up-arrow-'+index)
            let down = document.getElementById('down-arrow-'+index)
            cont.style.display = 'none'
            up.style.display = 'none'
            down.style.display = 'block'
          },
          showValue(index, pindex) {
            let value = document.getElementById('value-hover-'+index+'-'+pindex)
            value.style.display = 'block'
          },
          hideValue(index, pindex) {
            let value = document.getElementById('value-hover-'+index+'-'+pindex)
            value.style.display = 'none'
          },

          async updateImage({file, id}, callback)
          {
            let formData = new FormData()
            formData.append('id', id)
            formData.append('image', file)
            await axios.post('projects/scenarios/collections/image', formData)
            callback()
          },
          updateData(obj) {
            if(!this.viewer) {
              let param = this.activeModuleCollection.properties?.find(item => item.property_collection_id === obj.property_collection_id);
              if(!param) {
                this.activeModuleCollection.properties.push({
                  module_collection_id: this.activeModuleCollection.id,
                  module_id: this.activeModuleCollection.module_id,
                  property_collection_id: obj.property_collection_id,
                  sample_value: obj.sample_value
                });
              }

              obj.module_id = this.activeModule;
                this.$store.dispatch("build/updatePropertyItemFromBuild", obj).then(response => {
                this.$store.dispatch("build/getModuleDataOnLeftNavigator", this.activeModule);
              });
            }
          },
          getAssociatedData() {
              if(this.activeModuleCollection) {
                  this.$store.dispatch("association/getAssociatedData", this.activeModuleCollection.id);
              }
          },
          removeFromAssociation(id) {
              const self = this;
              self.$store.dispatch("association/removeAssociation", id);
          },
          updateItem(obj, e = null) {
            let objData = Object.assign({}, obj);
            if(e) {
              objData.description = e.target.innerText;
            }
            this.$store.dispatch("build/regularUpdate", objData);
          },
          autoHeight(e) {
            let textarea = e.target
            textarea.addEventListener("input", function (e) {
              this.style.height = "auto";
              this.style.height = this.scrollHeight + "px";
            });
          },
          updateProperty(property) {
            this.$store.dispatch("manageProperty/updateProperty", {
              id: property.id,
              title: property.title,
              module_id: property.module_id
            });
          },
          updateParameter(parameter) {
            this.$store.dispatch("manageProperty/updatePropertyCollectionItem", {
              id: parameter.id,
              title: parameter.title
            });
          }
        },
        mounted() {
          this.getAssociatedData();
        }
    }
</script>

<style scoped>
.property-body {
  padding: 35px 0;
  max-height: 907px;
  overflow-y: auto;
  overflow-x: hidden;
}
.prop-items {
  width: 150px;
}
.assoc-items {
  width: 250px;
}
.track-box {
  width: 140px;
}
.value-track, .progress-track, .range {
  height: 2px;
}
.range {
  outline: none;
  -webkit-appearance: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  -webkit-appearance: none;
  height: 10px !important;
  cursor: pointer;
  background: #00A7FE;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}
</style>
