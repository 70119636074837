<template>
  <div>
    <div class="flex mt-9">
      <img src="/images/icons/build/navigation.svg" alt="icon"
           @click="toggleSelectedOption('list'), manageListClickCount()"
           class="i-menu-icon cursor-pointer opacity-50 hover:bg-secondary-three"
           :class="{'opacity-100': selectedOption === 'list'}">
      <img src="/images/icons/build/global_library.svg" alt="icon" @click="toggleSelectedOption('library')"
           class="i-menu-icon cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
           :class="{'opacity-100': selectedOption === 'library'}">
      <img src="/images/icons/build/create.svg" alt="icon" @click="toggleSelectedOption('create')"
           class="i-menu-icon cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
           :class="{'opacity-100': selectedOption === 'create'}">
      <img src="/images/icons/build/properties.svg" alt="icon" @click="toggleSelectedOption('property')"
           class="i-menu-icon cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
           :class="{'opacity-100': selectedOption === 'property'}">
      <img src="/images/icons/brainstorm/TagIcon.svg" alt="icon" @click="toggleSelectedOption('tagList')"
           class="i-menu-icon w-6 cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
           :class="{'opacity-100': selectedOption === 'tagList'}">
    </div>
    <div>
      <module-list :viewer="viewer" v-if="selectedOption === 'list'"/>
      <library-list :viewer="viewer" v-if="selectedOption === 'library'"/>
      <ManualCreate :viewer="viewer" v-if="selectedOption === 'create'"/>
      <property :viewer="viewer" v-if="selectedOption === 'property'"/>
      <AttachedTags class="overflow-auto mt-10" style="height: 900px" v-if="selectedOption === 'tagList'" />
    </div>
  </div>
</template>

<script>
import ManualCreate from "@/components/build_identification/ManualCreate";
import ModuleList from "./ModuleList";
import LibraryList from "./LibraryList";
import Property from "./Property";

export default {
  name: "LeftPart",
  props: ['viewer', 'permission'],
  created() {
    const self = this;
    self.$store.dispatch("project/loadBuildGlobalProjects", this.$route.params.id);
  },
  components: {
    Property,
    LibraryList,
    ModuleList,
    ManualCreate
  },
  data() {
    return {
      selectedOption: 'list',
    }
  },
  methods: {
    toggleSelectedOption(value) {
      this.selectedOption = value;
    },
    manageListClickCount() {
      this.$emit("manageListClickCount");
    }
  }
}
</script>

<style scoped>

</style>
